/* eslint-disable */

import React from "react"

export default function Example({
  title = "testimonials",
  subtitle = "See what others have to say",
  description = "Start building for free, then add a site plan to go live. Account plans unlock additional features.",
  layout = "center"
}) {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto pt-16 px-4 sm:pt-24 sm:px-6 lg:px-8">
        <div className={`${layout === "center" ? "text-center" : ""}`}>
          <p className="text-base font-semibold text-sea-pink-700 tracking-wide uppercase">
            {title}
          </p>
          <h2 className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            {subtitle}
          </h2>
          <p
            className={`${
              layout === "center" ? "mx-auto" : ""
            } max-w-xl mt-5  text-xl text-jet`}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  )
}
