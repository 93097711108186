import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { openPopupWidget } from "react-calendly"

const onClick = () =>
  openPopupWidget({
    url: "https://calendly.com/sincerelyeden/discovery-call"
  })

export default function Example({ programCTA }) {
  return (
    <div className="relative py-16 bg-white">
      <div
        className="hidden absolute top-0 inset-x-0 h-1/2 bg-gray-50 lg:block"
        aria-hidden="true"
      />
      <div className="max-w-7xl mx-auto bg-sea-pink-100 lg:bg-transparent lg:px-8">
        <div className="lg:grid lg:grid-cols-12">
          <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
            <div
              className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden"
              aria-hidden="true"
            />{" "}
            <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0 pt-6">
              <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                <GatsbyImage
                  style={{ position: "absolute" }}
                  className="object-cover object-center rounded-3xl shadow-2xl"
                  image={programCTA}
                />
              </div>
            </div>
            {/* <img
                  className="object-cover object-center rounded-3xl shadow-2xl"
                  src="https://images.unsplash.com/photo-1507207611509-ec012433ff52?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=934&q=80"
                  alt=""
                />
              </div>
            </div> */}
          </div>

          <div className="relative bg-sea-pink-100 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
            <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
              <h2
                className="text-5xl font-extrabold text-gray-900"
                id="join-heading"
              >
                Ready to start your healing journey?
              </h2>
              <p className="text-lg text-jet">
                It’s time we stop normalizing negative, cycle-related symptoms.
                Women are not destined to suffer!
              </p>
              <button
                onClick={onClick}
                className="block w-full py-3 px-5 text-center bg-white border border-transparent rounded-md shadow-md font-medium bg-porsche-700 hover:bg-porsche-900 text-white sm:inline-block sm:w-auto"
                href="#"
              >
                Book a FREE discovery call today!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
