/* eslint-disable */

import React, { useState, useRef } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Notification from "./notification"

export default function Example({
  titleStart = (
    <>
      I’m Eden, <br />
    </>
  ),
  titleEnd = " your hormone health go-to girl. ",
  subtitle = (
    <>
      I empower women to get to the SOURCE of their hormonal imbalances, create
      LASTING habits, and FLOURISH every single day of the month - just as
      nature intended.
    </>
  ),
  ctaTitle = "",
  ctaBtnText,
  image,
  cta = false,
  onSubmit = () => {},
  quote = (
    <>
      Struggling to feel GOOD in <br />
      your female body + mind?
    </>
  ),
  isLoading = false,
  isSuccess = false
}) {
  const [input, setInput] = useState("")
  const [show, setShow] = useState(false)
  const hidden = useRef(false)

  const handleSubmit = e => {
    e.preventDefault()
    onSubmit(input)
  }

  if (isSuccess && hidden.current === false) {
    setShow(true)
    hidden.current = true

    setTimeout(() => setShow(false), 7000)
  }

  const disabledCTA = isLoading || isSuccess

  return (
    <div className="relative overflow-hidden bg-blob bg-auto bg-contain bg-no-repeat bg-center">
      <div className="relative pt-6 pb-16 sm:pb-24 lg:pb-32">
        <main className="mt-4 sm:mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left  flex justify-center flex-col">
              <q className="text-3xl sm:text-4xl xl:text-5xl mb-4">{quote}</q>
              <h1>
                <span className="block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                  <span className="text-gray-900">{titleStart}</span>
                  {titleEnd && (
                    <span className="text-sea-pink-700">{titleEnd}</span>
                  )}
                </span>
              </h1>
              <p className="mt-4 text-base text-jet sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                {subtitle}
              </p>
              {cta && (
                <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0 relative">
                  <p className="text-base font-medium text-gray-900">
                    {ctaTitle}
                  </p>
                  <form onSubmit={handleSubmit} className="mt-3 sm:flex">
                    <label htmlFor="email" className="sr-only">
                      Email
                    </label>
                    <input
                      required
                      type="email"
                      name="email"
                      onChange={e => setInput(e.target.value)}
                      id="email"
                      value={input}
                      disabled={disabledCTA}
                      className="block w-full py-3 text-base rounded-md placeholder-jet shadow-sm focus:ring-porsche-500 focus:border-porsche-500 sm:flex-1 border-gray-300"
                      placeholder="Enter your email"
                    />
                    <button
                      disabled={disabledCTA}
                      type="submit"
                      className="mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-porsche-700 shadow-sm hover:bg-porsche-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-porsche-500 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
                    >
                      {ctaBtnText}
                    </button>
                  </form>
                  {isSuccess && <Notification show={show} />}
                </div>
              )}
            </div>
            <div className="mt-12 relative sm:max-w-lg lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 flex mx-auto lg:justify-end  lg:items-center">
              <div className="relative w-full rounded-lg overflow-hidden shadow-lg lg:max-w-lg">
                <GatsbyImage
                  className="w-full"
                  image={image}
                  alt="sitting at beach"
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
